@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;1,400&display=swap');

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background: whitesmoke;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.heading-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0.3rem;
    padding: 1rem;
}

.heading {
    font-weight: 700;
    color: rgba(9, 9, 9, 0.75);
}    

p {
    font-weight: 400;
    color: rgba(9, 9, 9, 0.589);
    text-align: center;
}

.screen {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    word-wrap: break-word;
    word-break: break-all;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: 6rem;
    max-height: 7rem;
    max-width: 300px;
    font-size: 2rem;
    padding: 1.5rem;
    color: rgba(245, 238, 238, 0.913);
    background: rgba(36, 36, 36, 0.75);
    border: 1px solid white;
    border-radius: 1.5%;
}

.screen > span {
    font-size: 1rem;
    color: rgba(245, 238, 238, 0.913);
}

.calculator {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border-radius: 1%;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.032);
    overflow: hidden;    
}

.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(4rem, auto) repeat(3, 4rem);
}

.keypad > button {
    background: #e6e6fa;   
}

.keypad > button:hover {
    background: lavenderblush;
}

.operator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
}

.operator > button {
    flex: 1;
    background: #ffbc40;
    padding: 1.5rem;
    font-size: 1rem;
}

.operator > button:hover {
    background: #ffdb73;
} 

button {
    font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    border: white 1px solid;
    border-radius: 5%;
    transform: scale(0.9);
    box-shadow: 2px 2px 10px #8a8a8a;
}